import React from "react";
import { DataGrid1 as DataGrid } from "@kayugasolution/ui";
import CustomStore from "devextreme/data/custom_store";
import { httpClient } from "@kayugasolution/fn";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";

function Component(props) {
  const userStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return httpClient({ url: `${url}/cof/user`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return httpClient({ url: `${url}/cof/user`, method: "Post", data: data });
      },
      update: (_, data) => {
        return httpClient({ url: `${url}/cof/user`, method: "Put", data: data });
      },
      remove: (id) => {
        return httpClient({ url: `${url}/cof/user/${id}`, method: "Delete" });
      },
    }),
  };

  const userLookupStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return httpClient({
          url: `${url}/user`,
          loadOptions: loadOptions,
        });
      },
      byKey: function (id) {
        return httpClient({ url: `${url}/user/${id}` });
      },
    }),
  };

  // const

  const roles = [
    { id: 0, name: "User" },
    { id: 1, name: "Admin" },
  ];

  // render

  return (
    <DataGrid
      dataSource={userStore}
      title={"User"}
      rowNumbering={true}
      allowDownloading={false}
      onSelectionChanged={props.onSelectionChanged}
    >
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column caption={"UserName"} dataField={"id"} sortOrder={"desc"}>
        <Lookup dataSource={userLookupStore} valueExpr={"id"} displayExpr="userName" />
        <RequiredRule />
      </Column>
      <Column caption={"Role"} dataField={"roleId"} width={200}>
        <Lookup dataSource={roles} valueExpr={"id"} displayExpr="name" />
        <RequiredRule />
      </Column>
    </DataGrid>
  );
}

export default Component;
