import { useRef } from "react";
import { httpClient } from "@kayugasolution/fn";
import { DataGrid1 as DataGrid, ProgressPanel1 as ProgressPanel } from "@kayugasolution/ui";
import CustomStore from "devextreme/data/custom_store";
import { Column, FilterRow } from "devextreme-react/data-grid";
import { url } from "config.js";
import { runJob } from "@kayugasolution/rd";
import { useDispatch } from "react-redux";

function Component() {
  //
  // stores

  const facilityStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return httpClient({ url: `${url}/cof/class`, loadOptions: loadOptions });
      },
      update: (_, data) => {
        return httpClient({ url: `${url}/cof/class`, method: "Put", data: data });
      },
    }),
  };

  // hooks

  const exporting = useRef(false);
  let dispatch = useDispatch();

  // event handlers

  async function onUploaded(fileId) {
    dispatch(runJob({ name: "UPLOAD_CLASS", url: `${url}/cof/class/upload/file/${fileId}`, onCancel: true, responseType: "blob" }));
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    exporting.current = false;
  }

  // render

  return (
    <ProgressPanel name="UPLOAD_CLASS">
      <DataGrid
        title={"Class"}
        dataSource={facilityStore}
        selection={{ mode: "single" }}
        resetRowSelectionOnLoad={true}
        allowAdding={false}
        allowUpdating={true}
        allowDeleting={false}
        allowDownloading={true}
        onExported={onExported}
        onExporting={onExporting}
        onUploaded={onUploaded}
      >
        <FilterRow visible={true} />
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
        <Column
          caption={"Operation"}
          dataField={"operation"}
          calculateCellValue={() => "Update"}
          width={100}
          visible={false}
          formItem={{ visible: false }}
        />
        <Column caption={"Name"} dataField={"name"} allowEditing={false}></Column>
        <Column caption={"Cof"} dataField={"cof"} width={200}></Column>
      </DataGrid>
    </ProgressPanel>
  );
}

export default Component;
