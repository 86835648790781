import { useEffect } from "react";
import styles from "./app.module.scss";
import "theme/material-dark.css";
import "theme/typography.scss";
import "theme/components/datagrid.scss";
import "theme/components/treelist.scss";
import "theme/components/treeview.scss";
import "theme/components/form.scss";
import "theme/components/toolbar.scss";
import { notify, withMemo } from "@kayugasolution/fn";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIsAuthenticated, authenticate } from "store/auth";
import { getUser } from "@kayugasolution/rd";
import Layout from "layout/layout";
import { navigation } from "layout/navigation";
import { LoadingIndicator1 as LoadingIndicator, ErrorPage } from "@kayugasolution/ui";
import Lookup from "./lookup/lookup";
import CofFacility from "lookup/coffacility/cofacility";
import CofClass from "lookup/cofclass/cofclass";
import CofRating from "lookup/cofrating/cofrating";
import Input from "./input/input";
import Output from "./output/output";
import User from "./admin/user";

function Component() {
  //
  // hooks

  const queryParams = new URLSearchParams(useLocation().search);

  const isAuthenticated = useSelector(getIsAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    let id = queryParams.get("id");
    let jwt = queryParams.get("jwt");
    (async () => {
      await dispatch(authenticate({ id: id, jwt: jwt }));
    })();
  }, []);

  switch (isAuthenticated) {
    case null:
      return (
        <div className={styles.main}>
          <LoadingIndicator />
        </div>
      );
    case true:
      return (
        <div className={styles.main}>
          <MemoLayout />
        </div>
      );
    default:
      return (
        <div className={styles.main}>
          {notify("Access Denied", "error")}
          <ErrorPage />
        </div>
      );
  }
}

export default Component;

const MemoLayout = withMemo(() => {
  //
  // hooks

  let user = useSelector(getUser);

  // functions

  function setNavigation() {
    const nav = JSON.parse(JSON.stringify(navigation));
    const admin = nav.find((i) => i.text === "Admin");
    admin.visible = user.roleId === 1;
    return nav;
  }

  // render

  async function alert() {
    return await alert(`<div style="color: red; width: 600px;"><span style="font-size: 14px;">${"result.message"}</span></div>`, "Error");
  }

  return (
    <Layout navigation={setNavigation()} selectedPath={"/cof/input"}>
      <Routes>
        <Route path="klient/cof/lookup" element={<Lookup />}>
          <Route path="" element={<CofClass />} />
          <Route path="class" element={<CofClass />} />
          <Route path="facility" element={<CofFacility />} />
          <Route path="rating" element={<CofRating />} />
        </Route>
        <Route path="klient/cof/input" element={<Input />} />
        <Route path="klient/cof/output" element={<Output />} />
        <Route path="klient/cof/admin" element={<User />} />
        <Route path="klient/cof/admin/access" element={<User />} />
        <Route path="*" element={<Navigate to="/klient/cof/input" replace />} />
      </Routes>
    </Layout>
  );
}, []);
