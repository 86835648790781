import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "store";
import axios from "axios";
import App from "./app";
import { notify } from "@kayugasolution/fn";

//axios
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && 401 === error.response.status) {
      notify("Access denied", "error");
    } else {
      return Promise.reject(error);
    }
  }
);

// this is required for devextreme file-uploader to work.
let open = XMLHttpRequest.prototype.open;
XMLHttpRequest.prototype.open = function () {
  open.apply(this, arguments);
  this.withCredentials = true;
};

// root
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
    </Provider>
  </BrowserRouter>
);
