import styles from "./lookup.module.scss";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { Tabs } from "@kayugasolution/ui";
import { selectedTabItem } from "@kayugasolution/fn";

function Component() {
  //
  // hooks

  let navigate = useNavigate();
  let location = useLocation();

  // event handlers

  function changeView(e) {
    navigate(e.path);
  }

  // render

  const tabItems = [
    {
      text: "Class",
      icon: "fad fa-users-class",
      path: "class",
    },
    {
      text: "Facility",
      icon: "fad fa-industry",
      path: "facility",
    },

    {
      text: "Rating",
      icon: "fad fa-line-columns",
      path: "rating",
    },
  ];

  return (
    <div className={styles.main}>
      <div className={`${styles.tabs}`}>
        <Tabs
          tabItems={tabItems}
          selectedItem={selectedTabItem(tabItems, location.pathname, 4)}
          changeView={changeView}
          className={"dx-theme-border-color"}
        />
      </div>
      <div className={styles.container}>
        <Outlet />
      </div>
    </div>
  );
}

export default Component;
