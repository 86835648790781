import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpClient, notify } from "@kayugasolution/fn";
import { fetchOrganization, fetchPreference, fetchUser } from "@kayugasolution/rd";
import { url } from "config.js";

const initialState = {
  isAuthenticated: null,
};

export const authenticate = createAsyncThunk("auth/authenticate", async (params, { getState, dispatch }) => {
  var { id, jwt } = params;
  // jwt = jwt || sessionStorage.getItem("jwt") || "";
  // if (jwt) sessionStorage.setItem("jwt", jwt);
  // let response = await httpClient({ url: `${url}/cof/auth/`, method: "Post", params: { appId: id }, token: jwt });
  if (id !== null && jwt !== null) sessionStorage.setItem("_iris_cof", JSON.stringify(params));
  var stored = id === null ? JSON.parse(sessionStorage.getItem("_iris_cof")) || null : { ...params };
  let response = await httpClient({ url: `${url}/cof/auth/`, method: "Post", params: { appId: stored.id }, token: stored.jwt });
  if (response) {
    await Promise.all([dispatch(fetchOrganization()), dispatch(fetchUser()), dispatch(fetchPreference())]);
  }
  let state = getState();
  console.log(state);
  return response && state.org !== null && state.user !== null;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(authenticate.fulfilled, (state, action) => {
        state.isAuthenticated = action.payload;
      })
      .addCase(authenticate.rejected, (state, action) => {
        state.isAuthenticated = false;
        notify("Access Denied", "error");
      });
  },
});

export const getIsAuthenticated = (state) => state.auth.isAuthenticated;

export default authSlice.reducer;
