import React, { useState, useEffect } from "react";
import styles from "./footer.module.scss";
import Popup from "devextreme-react/popup";
import { httpClient } from "@kayugasolution/fn";
import { url } from "config.js";
import Form, { GroupItem, SimpleItem, Label } from "devextreme-react/form";

function Component() {
  //
  // hooks

  const [state, setState] = useState(false);

  //

  function envRender() {
    return process.env.REACT_APP_CUSTOM_NODE_ENV === "Production" ? "" : `${process.env.REACT_APP_CUSTOM_NODE_ENV}`;
  }

  // render

  return (
    <div className={styles.main}>
      <div
        className={styles.item}
        onClick={() => {
          setState(true);
        }}
      >
        IRIS by Kayuga {`(${process.env.REACT_APP_DOC_VERSION})`} {envRender()}
      </div>
      {state && (
        <Popup
          visible={state}
          onHiding={() => {
            setState(false);
          }}
          showCloseButton={true}
          dragEnabled={true}
          closeOnOutsideClick={false}
          showTitle={true}
          title={"Info"}
          width={400}
          height={300}
          deferRendering={true}
        >
          <Footer />
        </Popup>
      )}
    </div>
  );
}

export default Component;

function Footer() {
  const [state, setState] = useState({});

  useEffect(() => {
    (async () => {
      try {
        var p1 = httpClient({ url: `${url}/info/version` });
        var p2 = httpClient({ url: `${url}/info/region` });
        const [version, region] = await Promise.all([p1, p2]);
        setState({ version, region });
      } catch (e) {
        console.log(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editorOptions = { stylingMode: "filled", height: "40px", readOnly: true };

  return (
    <Form formData={state} labelLocation={"top"} minColWidth={200} showColonAfterLabel={true} showRequiredMark={false}>
      <GroupItem caption={""} colCount={1} cssClass={"theme-form"}>
        <SimpleItem dataField="region" colSpan={1} editorOptions={editorOptions}>
          <Label text={"Region"} />
        </SimpleItem>
        <SimpleItem dataField="version" colSpan={1} editorOptions={editorOptions}>
          <Label text={"API version"} />
        </SimpleItem>
      </GroupItem>
    </Form>
  );
}
