import React from "react";
import "./header.scss";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import { useLocation } from "react-router-dom";
import UserPanel from "../user-panel/user-panel";
import { useSelector, useDispatch } from "react-redux";
import { getOrganization } from "@kayugasolution/rd";
import { toggleSideMenu } from "store/ui";

function Component() {
  //
  // hooks

  let location = useLocation();
  let org = useSelector(getOrganization);
  let dispatch = useDispatch();

  // const

  const hideTitle = location.pathname === "/klient" || location.pathname === "/klient/home";

  // render

  function renderLabel() {
    return <div className="klient-layout-header-organization">{org.displayName}</div>;
  }

  return (
    <header className={"klient-layout-header"}>
      <Toolbar className={"toolbar"}>
        <Item
          location={"before"}
          widget={"dxButton"}
          cssClass={"menu"}
          options={{
            icon: "fas fa-bars",
            stylingMode: "text",
            onClick: () => {
              dispatch(toggleSideMenu());
            },
          }}
        />
        {!hideTitle && (
          <Item location={"center"} locateInMenu="never">
            <div className="klient-layout-header-logo">
              <img className="klient-layout-header-logo" alt="/" src={org.headerLogoUrl} height={org.headerLogoHeight} />
            </div>
          </Item>
        )}
        {!hideTitle && <Item location="center" locateInMenu="never" render={renderLabel} />}
        <Item location={"after"} cssClass={"user"}>
          <div className={"klient-layout-header-avatar"}>
            <UserPanel />
          </div>
        </Item>
      </Toolbar>
    </header>
  );
}

export default Component;
