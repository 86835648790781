export const navigation = [
  {
    text: "COF",
    icon: "fad fa-calculator",
    items: [
      {
        text: "Lookup",
        path: "/klient/cof/lookup",
        icon: "fad fa-caret-square-down",
      },
      {
        text: "Input",
        path: "/klient/cof/input",
        icon: "fad fa-keyboard",
      },
      {
        text: "Output",
        path: "/klient/cof/output",
        icon: "fad fa-book-open",
      },
    ],
  },
  {
    text: "Admin",
    icon: "fad fa-users-crown",
    items: [
      {
        text: "Access Control",
        path: "/klient/cof/admin/access",
        icon: "fad fa-user-lock",
      },
    ],
  },
];
