import React, { useState, useRef, useEffect } from "react";
import styles from "./input.module.scss";
import { withMemo } from "@kayugasolution/fn";
import { httpClient } from "@kayugasolution/fn";
import { DataGrid1 as DataGrid, ProgressPanel1 as ProgressPanel } from "@kayugasolution/ui";
import CustomStore from "devextreme/data/custom_store";
import { Column, FilterRow, Lookup } from "devextreme-react/data-grid";
import { url } from "config.js";
import { runJob } from "@kayugasolution/rd";
import { useDispatch } from "react-redux";
import { RequiredRule } from "devextreme-react/validator";

function Component() {
  //
  // hooks

  const [facility, setFacility] = useState({ id: null });
  const [node, setNode] = useState({ id: null });

  // event handlers

  function onFacilitySelectionChanged(e) {
    setFacility(e.selectedRowsData[0] || { id: null });
  }

  function onNodeSelectionChanged(e) {
    setNode(e.selectedRowsData[0] || { id: null });
  }

  return (
    <div className={styles.main}>
      <div className={styles.facility}>
        <Facility onSelectionChanged={onFacilitySelectionChanged} />
      </div>
      <div className={styles.container}>
        <ProgressPanel name="UPLOAD_NODERATING">
          <div className={styles.node}>
            <Node facilityId={facility.id} onSelectionChanged={onNodeSelectionChanged} />
          </div>
          <div className={styles.rating}>
            <Rating nodeId={node.id} />
          </div>
        </ProgressPanel>
      </div>
    </div>
  );
}

export default Component;

const Facility = withMemo((props) => {
  //
  // stores
  const facilityStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return httpClient({ url: `${url}/cof/facility`, loadOptions: loadOptions });
      },
      update: (_, data) => {
        return httpClient({ url: `${url}/cof/facility`, method: "Put", data: data });
      },
    }),
  };

  // render

  return (
    <DataGrid
      title={"Facility"}
      dataSource={facilityStore}
      selection={{ mode: "single" }}
      resetRowSelectionOnLoad={true}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={false}
      onSelectionChanged={props.onSelectionChanged}
    >
      <FilterRow visible={true} />
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"name"} allowEditing={false} allowFiltering={true}></Column>
    </DataGrid>
  );
}, []);

const Node = withMemo(
  (props) => {
    //
    // stores
    const nodeStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          if (!props.facilityId) return Promise.resolve([]);
          return httpClient({ url: `${url}/cof/nodefacility`, loadOptions: loadOptions, params: { facilityId: props.facilityId } });
        },
        update: (_, data) => {
          return httpClient({ url: `${url}/cof/nodefacility`, method: "Put", data: data });
        },
      }),
    };

    // render

    return (
      <DataGrid
        title={"Folder"}
        dataSource={nodeStore}
        selection={{ mode: "single" }}
        resetRowSelectionOnLoad={true}
        allowAdding={false}
        allowUpdating={false}
        allowDeleting={false}
        allowDownloading={false}
        onSelectionChanged={props.onSelectionChanged}
      >
        <FilterRow visible={true} />
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
        <Column caption={"Name"} dataField={"name"} />
        <Column caption={"Description"} dataField={"description"} width={400} />
        <Column caption={"Parent"} dataField={"parent"} width={400} />
      </DataGrid>
    );
  },
  ["facilityId"]
);

const Rating = withMemo(
  (props) => {
    //
    // stores

    const nodeRating = {
      store: new CustomStore({
        key: "ratingId",
        useDefaultSearch: true,
        load: (loadOptions) => {
          if (!props.nodeId) return Promise.resolve([]);
          return exporting.current
            ? httpClient({ url: `${url}/cof/noderating/download`, loadOptions: loadOptions, params: { nodeId: props.nodeId } })
            : httpClient({ url: `${url}/cof/noderating`, loadOptions: loadOptions, params: { nodeId: props.nodeId } });
        },
        update: (_, data) => {
          return httpClient({ url: `${url}/cof/noderating`, method: "Put", data: data });
        },
      }),
    };

    const ratingScoreStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          return httpClient({ url: `${url}/cof/ratingscore`, loadOptions: loadOptions, params: { ratingId: ratingId.current } });
        },
        byKey: function (id) {
          return httpClient({ url: `${url}/cof/ratingscore/${id}` });
        },
      }),
    };

    // hooks

    let exporting = useRef(false);
    let ratingId = useRef(0);
    let dispatch = useDispatch();

    // event handlers

    function onEditorPreparing(e) {
      ratingId.current = (e.row && e.row.data.ratingId) || 0;
    }

    async function onUploaded(fileId) {
      dispatch(
        runJob({ name: "UPLOAD_NODERATING", url: `${url}/cof/noderating/upload/file/${fileId}`, onCancel: true, responseType: "blob" })
      );
    }

    function onExporting(e) {
      e.component.columnOption("id", "visible", true);
      e.component.columnOption("id", "sortOrder", "asc");
      e.component.columnOption("operation", "visible", true);
      e.component.columnOption("facility", "visible", true);
      e.component.columnOption("name", "visible", true);
      exporting.current = true;
    }

    function onExported(e) {
      e.component.columnOption("id", "visible", false);
      e.component.columnOption("id", "sortOrder", "desc");
      e.component.columnOption("operation", "visible", false);
      e.component.columnOption("facility", "visible", false);
      e.component.columnOption("name", "visible", false);
      exporting.current = false;
    }

    // render

    return (
      <DataGrid
        keyExpr={"ratingId"}
        title={"Rating Score"}
        editingMode="batch"
        showButtons={false}
        dataSource={nodeRating}
        resetRowSelectionOnLoad={true}
        allowAdding={false}
        allowUpdating={true}
        allowDeleting={false}
        allowDownloading={true}
        onExported={onExported}
        onExporting={onExporting}
        onUploaded={onUploaded}
        onEditorPreparing={onEditorPreparing}
      >
        <FilterRow visible={true} />
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
        <Column
          caption={"Operation"}
          dataField={"operation"}
          calculateCellValue={() => "Update"}
          width={100}
          visible={false}
          formItem={{ visible: false }}
        />
        <Column caption={"Facility"} dataField={"facility"} visible={false} width={200} />
        <Column caption={"Name"} dataField={"name"} visible={false} width={200} />
        <Column caption={"Rating"} dataField={"rating"} allowEditing={false} visible={true} />{" "}
        <Column caption={"Score"} dataField={"ratingScoreId"} calculateDisplayValue="ratingScore">
          <Lookup dataSource={ratingScoreStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
        </Column>
      </DataGrid>
    );
  },
  ["nodeId"]
);
