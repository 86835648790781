import { configureStore } from "@reduxjs/toolkit";
import infoReducer from "./info";
import authReducer from "./auth";
import uiReducer from "./ui";
import { orgReducer, userReducer, prefReducer, jobReducer } from "@kayugasolution/rd";

export const store = configureStore({
  reducer: {
    info: infoReducer,
    auth: authReducer,
    org: orgReducer,
    user: userReducer,
    ui: uiReducer,
    pref: prefReducer,
    job: jobReducer,
  },
});
