import React, { useRef, useState } from "react";
import styles from "./output.module.scss";
import { withMemo, httpClient, notify } from "@kayugasolution/fn";
import { DataGrid1 as DataGrid, ProgressPanel1 as ProgressPanel, DataGrid2 as List } from "@kayugasolution/ui";
import CustomStore from "devextreme/data/custom_store";
import { Column, FilterRow, Sorting } from "devextreme-react/data-grid";
import { url } from "config.js";
import { confirm } from "devextreme/ui/dialog";
import { useDispatch } from "react-redux";
import { runJob } from "@kayugasolution/rd";

function Component() {
  //
  // hooks

  let [facility, setFacility] = useState({ id: null });
  let dispatch = useDispatch();
  let selected = useRef(null);

  // event handlers

  function onFacilitySelectionChanged(e) {
    setFacility(e.selectedRowsData[0] || { id: null });
    selected.current = e.selectedRowsData[0] || { id: null };
  }

  async function onRunFacility() {
    var result = await confirm("<i>Do you really want to run the the selected facility?</i>", "Confirm");
    if (result) {
      try {
        await dispatch(runJob({ name: "RUN", url: `${url}/cof/run/facility/${selected.current.id}` }));
      } catch (error) {
        notify("error", error);
      }
    }
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.facility}>
        <Facility onRunFacility={onRunFacility} onSelectionChanged={onFacilitySelectionChanged} />
      </div>
      <div className={styles.output}>
        <ProgressPanel name="RUN">
          <Rating facilityId={facility.id} />
        </ProgressPanel>
      </div>
    </div>
  );
}

export default Component;

const Facility = withMemo((props) => {
  //
  // stores
  const facilityStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return httpClient({ url: `${url}/cof/facility`, loadOptions: loadOptions });
      },
      update: (_, data) => {
        return httpClient({ url: `${url}/cof/facility`, method: "Put", data: data });
      },
    }),
  };

  // hooks

  let dispatch = useDispatch();

  // event handlers

  async function onRun() {
    var result = await confirm("<i>Do you really want to run all the facilities?</i>", "Confirm");
    if (result) {
      try {
        await dispatch(runJob({ name: "RUN", url: `${url}/cof/run` }));
      } catch (error) {
        notify("error", error);
      }
    }
  }

  // render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: "before",
      widget: "dxButton",

      options: {
        hint: "Run",
        icon: "fas fa-play",
        onClick: onRun,
      },
    });
  }

  function onContextMenuPreparing(e) {
    e.items.push({
      text: "Run",
      icon: "fas fa-play",
      onClick: props.onRunFacility,
    });
  }

  return (
    <List
      title={"Facility"}
      dataSource={facilityStore}
      selection={{ mode: "single" }}
      resetRowSelectionOnLoad={true}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={false}
      onToolbarPreparing={onToolbarPreparing}
      onSelectionChanged={props.onSelectionChanged}
      onContextMenuPreparing={onContextMenuPreparing}
    >
      <FilterRow visible={true} />
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"name"} allowEditing={false} allowFiltering={true}></Column>
    </List>
  );
}, []);

const Rating = withMemo(
  (props) => {
    //
    // stores

    const cofStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          if (!props.facilityId) return Promise.resolve([]);
          return httpClient({ url: `${url}/cof/facility/factors`, loadOptions: loadOptions, params: { facilityId: props.facilityId } });
        },
        update: (_, data) => {
          return httpClient({ url: `${url}/cof/factors`, method: "Put", data: data });
        },
      }),
    };

    // hooks
    const exporting = useRef(false);

    // event handlers

    function onExporting(e) {
      e.component.columnOption("id", "visible", true);
      e.component.columnOption("id", "sortOrder", "asc");
      e.component.columnOption("operation", "visible", true);
      e.component.columnOption("facility", "visible", true);
      exporting.current = true;
    }

    function onExported(e) {
      e.component.columnOption("id", "visible", false);
      e.component.columnOption("id", "sortOrder", "desc");
      e.component.columnOption("operation", "visible", false);
      e.component.columnOption("facility", "visible", false);
      exporting.current = false;
    }

    // render

    return (
      <DataGrid
        title={"Output"}
        keyExpr={"Id"}
        dataSource={cofStore}
        allowAdding={false}
        allowUpdating={false}
        allowDeleting={false}
        allowDownloading={true}
        remoteOperations={false}
        onExported={onExported}
        onExporting={onExporting}
      >
        <Sorting mode="none" />
        <FilterRow visible={false} />
        <Column dataField={"id"} visible={false} />
        <Column caption={"Facility"} dataField={"facility"} visible={false} width={200} />
        <Column caption={"Name"} dataField={"name"} width={400} />
        <Column caption={"Description"} dataField={"description"} />
        <Column
          allowSorting={true}
          caption={"Weighted Score"}
          dataField={"weightedScore"}
          dataType={"number"}
          format={"0.###"}
          width={200}
        />
        <Column
          allowSorting={true}
          caption={"Normalized Weighted Score"}
          dataField={"normalizedWeightedScore"}
          dataType={"number"}
          format={"0.###"}
          width={200}
        />
        <Column
          allowSorting={true}
          caption={"Adjustment Factor"}
          dataField={"adjustmentFactor"}
          dataType={"number"}
          format={"0.###"}
          width={200}
        />
      </DataGrid>
    );
  },
  ["facilityId"]
);
