import React from "react";
import "./user-panel.scss";
import { useSelector } from "react-redux";
import { getUser } from "@kayugasolution/rd";

function Component() {
  //
  // hooks

  const user = useSelector(getUser);

  // const

  const avatarUrl = user.avatarUrl || `https://stirisprod001.blob.core.windows.net/avatars/Avatar.jpg`;

  return (
    <div className="components-user-panel">
      <div className="user-name">{user.name}</div>
      <div className="image-container">
        <img className="image" alt="/" src={avatarUrl} />
      </div>
    </div>
  );
}

export default Component;
