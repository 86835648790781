import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpClient, notify } from "@kayugasolution/fn";

const initialState = {
  version: "NA",
};

export const getVersion = createAsyncThunk("info/getVersion", async () => {
  let result = await httpClient({ url: ``, method: "Get" });
  return result;
});

const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVersion.fulfilled, (state, action) => {
        state.version = action.payload;
      })
      .addCase(getVersion.rejected, (state, action) => {
        notify("Access denied", "error");
        state.version = "Unable to read version";
      });
  },
});

export const readVersion = (state) => state.info.version;

export default infoSlice.reducer;
