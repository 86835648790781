import React, { useState, useRef } from "react";
import styles from "./cofrating.module.scss";
import { withMemo } from "@kayugasolution/fn";
import { httpClient } from "@kayugasolution/fn";
import { DataGrid1 as DataGrid, ProgressPanel1 as ProgressPanel } from "@kayugasolution/ui";
import CustomStore from "devextreme/data/custom_store";
import { Column, FilterRow, RequiredRule } from "devextreme-react/data-grid";
import { url } from "config.js";
import { runJob } from "@kayugasolution/rd";
import { useDispatch } from "react-redux";

function Component() {
  //
  // hooks

  const [facility, setFacility] = useState({ id: null });
  const [rating, setRating] = useState({ id: null });

  // event handlers

  function onFacilitySelectionChanged(e) {
    console.log(e);
    setFacility(e.selectedRowsData[0] || { id: null });
  }

  function onRatingSelectionChanged(e) {
    setRating(e.selectedRowsData[0] || { id: null });
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.facility}>
        <Facility onSelectionChanged={onFacilitySelectionChanged} />
      </div>
      <div className={styles.container}>
        <ProgressPanel name="UPLOAD_RATING">
          <div className={styles.rating}>
            <Rating facilityId={facility.id} onSelectionChanged={onRatingSelectionChanged} />
          </div>
          <div className={styles.score}>
            <Score facilityId={facility.id} ratingId={rating.id} />
          </div>
        </ProgressPanel>
      </div>
    </div>
  );
}

export default Component;

const Facility = withMemo((props) => {
  //
  // stores
  const facilityStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return httpClient({ url: `${url}/facility`, loadOptions: loadOptions });
      },
      update: (_, data) => {
        return httpClient({ url: `${url}/cof/facility`, method: "Put", data: data });
      },
    }),
  };

  // render

  return (
    <DataGrid
      title={"Facility"}
      dataSource={facilityStore}
      selection={{ mode: "single" }}
      resetRowSelectionOnLoad={true}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={false}
      onSelectionChanged={props.onSelectionChanged}
    >
      <FilterRow visible={true} />
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"name"} allowEditing={false} allowFiltering={true}></Column>
    </DataGrid>
  );
}, []);

const Rating = withMemo(
  (props) => {
    //
    // stores
    const ratingStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          if (!props.facilityId) return Promise.resolve([]);
          return exporting.current
            ? httpClient({ url: `${url}/cof/rating/download`, loadOptions: loadOptions })
            : httpClient({ url: `${url}/cof/rating`, loadOptions: loadOptions, params: { facilityId: props.facilityId } });
        },
        insert: (data) => {
          return httpClient({ url: `${url}/cof/rating`, method: "Post", data: data });
        },
        update: (_, data) => {
          return httpClient({ url: `${url}/cof/rating`, method: "Put", data: data });
        },
        remove: (id) => {
          return httpClient({ url: `${url}/cof/rating/${id}`, method: "Delete" });
        },
      }),
    };

    // hooks
    const exporting = useRef(false);
    let dispatch = useDispatch();

    // event handlers

    async function onUploaded(fileId) {
      dispatch(runJob({ name: "UPLOAD_RATING", url: `${url}/cof/rating/upload/file/${fileId}`, onCancel: true, responseType: "blob" }));
    }

    function onExporting(e) {
      e.component.columnOption("id", "visible", true);
      e.component.columnOption("id", "sortOrder", "asc");
      e.component.columnOption("operation", "visible", true);
      e.component.columnOption("facility", "visible", true);
      exporting.current = true;
    }

    function onExported(e) {
      e.component.columnOption("id", "visible", false);
      e.component.columnOption("id", "sortOrder", "desc");
      e.component.columnOption("operation", "visible", false);
      e.component.columnOption("facility", "visible", false);
      exporting.current = false;
    }

    // render

    return (
      <DataGrid
        title={"Rating"}
        dataSource={ratingStore}
        selection={{ mode: "single" }}
        resetRowSelectionOnLoad={true}
        allowAdding={true}
        allowUpdating={true}
        allowDeleting={true}
        allowDownloading={true}
        onExported={onExported}
        onExporting={onExporting}
        onUploaded={onUploaded}
        onSelectionChanged={props.onSelectionChanged}
        onInitNewRow={(e) => {
          e.data.facilityId = props.facilityId;
        }}
      >
        <FilterRow visible={true} />
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
        <Column
          caption={"Operation"}
          dataField={"operation"}
          calculateCellValue={() => "Update"}
          width={100}
          visible={false}
          formItem={{ visible: false }}
        />
        <Column caption={"Facility"} dataField={"facility"} visible={false} width={200} formItem={{ visible: false }} />
        <Column caption={"Name"} dataField={"name"} width={400}>
          <RequiredRule />
        </Column>
        <Column caption={"Description"} dataField={"description"} width={400} />
        <Column caption={"Weightage"} dataField={"weightage"} dataType={"number"} width={200}>
          <RequiredRule />
        </Column>
        <Column visible={false} formItem={{ itemType: "empty" }} />
        <Column
          caption={"Notes"}
          dataField={"notes"}
          allowFiltering={true}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
      </DataGrid>
    );
  },
  ["facilityId"]
);

const Score = withMemo(
  (props) => {
    //
    // stores

    const ratingScoreStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          if (!props.ratingId) return Promise.resolve([]);
          return exporting.current
            ? httpClient({ url: `${url}/cof/ratingscore/download`, loadOptions: loadOptions })
            : httpClient({ url: `${url}/cof/ratingscore`, loadOptions: loadOptions, params: { ratingId: props.ratingId } });
        },
        insert: (data) => {
          return httpClient({ url: `${url}/cof/ratingscore`, method: "Post", data: data });
        },
        update: (_, data) => {
          return httpClient({ url: `${url}/cof/ratingscore`, method: "Put", data: data });
        },
        remove: (id) => {
          return httpClient({ url: `${url}/cof/ratingscore/${id}`, method: "Delete" });
        },
      }),
    };

    // hooks

    const exporting = useRef(false);
    let dispatch = useDispatch();

    // event handlers

    async function onUploaded(fileId) {
      dispatch(
        runJob({ name: "UPLOAD_RATING", url: `${url}/cof/ratingscore/upload/file/${fileId}`, onCancel: true, responseType: "blob" })
      );
    }

    function onExporting(e) {
      e.component.columnOption("id", "visible", true);
      e.component.columnOption("id", "sortOrder", "asc");
      e.component.columnOption("operation", "visible", true);
      e.component.columnOption("facility", "visible", true);
      e.component.columnOption("rating", "visible", true);
      exporting.current = true;
    }

    function onExported(e) {
      e.component.columnOption("id", "visible", false);
      e.component.columnOption("id", "sortOrder", "desc");
      e.component.columnOption("operation", "visible", false);
      e.component.columnOption("facility", "visible", false);
      e.component.columnOption("rating", "visible", false);
      exporting.current = false;
    }

    // render

    return (
      <ProgressPanel name="UPLOAD_RATING">
        <DataGrid
          title={"Score"}
          dataSource={ratingScoreStore}
          resetRowSelectionOnLoad={true}
          allowAdding={true}
          allowUpdating={true}
          allowDeleting={true}
          allowDownloading={true}
          onExported={onExported}
          onExporting={onExporting}
          onUploaded={onUploaded}
          onInitNewRow={(e) => {
            e.data.facilityId = props.facilityId;
            e.data.ratingId = props.ratingId;
          }}
        >
          <FilterRow visible={true} />
          <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
          <Column
            caption={"Operation"}
            dataField={"operation"}
            calculateCellValue={() => "Update"}
            width={100}
            visible={false}
            formItem={{ visible: false }}
          />
          <Column caption={"Facility"} dataField={"facility"} visible={false} width={200} formItem={{ visible: false }} />
          <Column caption={"Rating"} dataField={"rating"} visible={false} width={200} formItem={{ visible: false }} />
          <Column caption={"Name"} dataField={"name"} width={400}></Column>
          <Column caption={"Description"} dataField={"description"} width={400}></Column>
          <Column caption={"Score"} dataField={"score"} dataType={"number"} width={200}>
            <RequiredRule />
          </Column>
          <Column visible={false} formItem={{ itemType: "empty" }} />
          <Column
            caption={"Notes"}
            dataField={"notes"}
            allowFiltering={true}
            formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
          />
        </DataGrid>
      </ProgressPanel>
    );
  },
  ["ratingId"]
);
